enum AudioActions {
  // action types
  GET_ALL_AUDIO = "getAllAudio",
  GET_DETAIL_AUDIO = "getDetailAudio",
  CREATE = "createAudio",
  UPDATE = "updateAudio",
  DELETE = "delAudio",
}

enum AudioMutations {
  // mutation types
  SET_LIST_AUDIO = "setListAudio",
  SET_AUDIO_ID_TARGET_UPSERT = "setAudioIdTargetUpsert",
}

enum AudioGetters {
  GET_BY_ID = "getAudioByAudioId",
}

export { AudioActions, AudioMutations, AudioGetters };
