import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AccountActions, AccountGetters, AccountMutations } from "./enums";

export interface Account {
  name: string;
  address: string;
  account_id: number;
  status: string;
}

export interface AccountState {
  list: Partial<Account>[];
  uniqueKey: string;
  errors: Array<string>;
}

export interface StudentAccountInformation {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string; // male, female
  birthday: string;
  classCode: string;
}

@Module
export default class AccountModule extends VuexModule implements AccountState {
  uniqueKey = "account";
  list: Account[] = [];
  errors = [];

  @Action({ rawError: true })
  async [AccountActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });
    return result;
  }

  @Action({ rawError: true })
  async [AccountActions.CHECK_USERNAME_EXIST](par: { username: string }) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `${this.uniqueKey}/checkUsernameExist`,
      body: {
        username: par.username,
      },
    });
    return result;
  }
}
