import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { NewsActions, NewsGetters, NewsMutations } from "./enums";

export interface News {
  id: number;
  title: string;
  content: string;
  desp: string;
  cover: string;
  tag: string;
  slug: string;
  show_owner: number;
  owner: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface NewsState {
  list: Partial<News>[];
  uniqueKey: string;
  errors: Array<string>;
  perPage: number;
  currentPage: number;
  totalItem: number;
  textSearch: string;
}

export interface PaginationConfig {
  perPage: number;
  currentPage: number;
  textSearch?: string;
}

@Module
export default class NewsModule extends VuexModule implements NewsState {
  uniqueKey = "news";
  list: News[] = [];
  errors = [];
  perPage = 20;
  currentPage = 1;
  totalItem = 1;
  textSearch = "";

  get [NewsGetters.GET_BY_ID]() {
    return (newsId: number) => {
      const detail = this.list.filter((m) => m.id === newsId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [NewsMutations.SET_LIST_NEWS](list: News[]) {
    this.list = list;
  }

  @Mutation
  [NewsMutations.SET_PAGINATION](paginationStatus) {
    this.perPage = parseInt(paginationStatus.perPage);
    this.currentPage = parseInt(paginationStatus.currentPage);
    this.totalItem = parseInt(paginationStatus.totalItem);
  }

  @Mutation
  [NewsMutations.SET_TEXT_SEARCH](textSearch) {
    this.textSearch = textSearch;
  }

  @Action({ rawError: true })
  async [NewsActions.GET_NEWS_LIST](paginationConfig: PaginationConfig) {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
      limit: paginationConfig?.perPage || this.perPage,
      page: paginationConfig?.currentPage || this.currentPage,
      data: {
        keyword: paginationConfig?.textSearch || this.textSearch,
      },
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      const paginationStatus = {
        perPage: result.metadata?.limit,
        currentPage: result.metadata?.current_page,
        totalItem: result.metadata?.total_item,
      };

      this.context.commit(NewsMutations.SET_PAGINATION, paginationStatus);
      this.context.commit(
        NewsMutations.SET_TEXT_SEARCH,
        result.metadata?.keyword || ""
      );
      this.context.commit(NewsMutations.SET_LIST_NEWS, result?.data);
    }
  }

  @Action({ rawError: true })
  async [NewsActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsActions.GET_NEWS_LIST);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NewsActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsActions.GET_NEWS_LIST);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NewsActions.GET_DETAIL](newsId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: newsId,
    });
    return result?.data;
  }

  @Action({ rawError: true })
  async [NewsActions.DELETE](newsId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: newsId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsActions.GET_NEWS_LIST);
    }
    return result;
  }
}
