import { StepResult } from "@/store/captain/CaptainModule";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const convertDateToWeekday = (date: Date): string => {
  const weekday = date.getDay();
  const list = {
    0: "Chủ nhật",
    1: "Thứ 2",
    2: "Thứ 3",
    3: "Thứ 4",
    4: "Thứ 5",
    5: "Thứ 6",
    6: "Thứ 7",
  };
  return list[weekday];
};

/**
 * @param minutes
 * @returns string format hh:mm 2 giờ 30 phút
 */
export const convertMinuteToHHMM = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hours === 0) {
    return `${mins} phút`;
  }
  if (mins === 0) {
    return `${hours} giờ`;
  }
  return `${hours} giờ ${mins} phút`;
};

export const convertDateStrYYYYMMDDToDate = (date: string): Date => {
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);
  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    0,
    0,
    0,
    0
  );
};

export const convertDateToHHmm = (date: Date): string => {
  const h = date.getHours();
  const m = date.getMinutes();
  const hStr = h < 10 ? `0${h}` : h;
  const mStr = m < 10 ? `0${m}` : m;
  return `${hStr}:${mStr}`;
};

interface MessageNotify {
  success: "string";
  error: "string";
}

export const notifyCommonResponse = (
  stepResult: StepResult,
  message?: Partial<MessageNotify>
) => {
  if (stepResult.isSuccess) {
    Swal.fire({
      position: "top-end",
      text: message?.success || "Success!",
      icon: "success",
      buttonsStyling: false,
      timer: 2000,
      width: 400,
      showCancelButton: false,
      showConfirmButton: false,
    });
  } else {
    Swal.fire({
      position: "top-end",
      text: message?.error || "Đã xảy ra lỗi, vui lòng thử lại sau!",
      icon: "error",
      buttonsStyling: false,
      timer: 2000,
      width: 400,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
};

export const convertDateToDDMMYYYGMT7 = (date): string => {
  const d = new Date(date);
  d.setHours(d.getHours() + 7);
  return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
};

export interface CsvHeader {
  id: string;
  title: string;
}

// Download CSV file
export const exportJsonToCsv = (
  headers: CsvHeader[],
  records: Record<string, any>[],
  filename: string
) => {
  const rows = transformJsonToRows(headers, records);
  exportRowsToCsv(filename, rows);
};

const transformJsonToRows = (
  headers: CsvHeader[],
  records: Record<string, any>[]
): any[][] => {
  const rows: string[][] = [];

  const headerRowTitle: string[] = [];
  const headerRowId: string[] = [];
  for (let index = 0; index < headers.length; index++) {
    const header = headers[index];
    headerRowTitle.push(header.title);
    headerRowId.push(header.id);
  }
  rows.push(headerRowTitle);

  for (let index = 0; index < records.length; index++) {
    const row: string[] = [];

    const json = records[index];
    for (let j = 0; j < headerRowId.length; j++) {
      const id = headerRowId[j];
      const value = json?.[id] || "";
      row.push(value);
    }
    rows.push(row);
  }
  return rows;
};

const exportRowsToCsv = (filename, rows) => {
  const processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
