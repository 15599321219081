/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTreeDataLesson } from "@/core/helpers/tree-data.helper";
import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { CourseActions, CourseGetters, CourseMutations } from "./enums";

export interface Course {
  course_id: number;
  name: string;
  description: string;
  brand_id: number;
  type_code: string;
  status: number;
  images: string;
  price: number;
  detail: string;
  url: string;

  document: string;
  video: string;
}

export interface CourseState {
  list: Partial<Course>[];
  uniqueKey: string;
  errors: Array<string>;
  treeDataLesson: any[];
}

@Module
export default class CourseModule extends VuexModule implements CourseState {
  uniqueKey = "course";
  list: Course[] = [];
  errors = [];
  treeDataLesson: any[] = [];

  get [CourseGetters.GET_BY_ID]() {
    return (courseId: number) => {
      const detail = this.list.filter((m) => m.course_id === courseId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [CourseMutations.SET_LIST_COURSE](list: Course[]) {
    this.list = list;
  }

  @Mutation
  [CourseMutations.SET_TREE_DATA_LESSON](treeDataLesson: any[]) {
    this.treeDataLesson = treeDataLesson;
  }

  @Action({ rawError: true })
  async [CourseActions.GET_ALL_COURSE]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(CourseMutations.SET_LIST_COURSE, result?.data);
    }
  }

  @Action({ rawError: true })
  async [CourseActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseActions.GET_ALL_COURSE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseActions.GET_ALL_COURSE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseActions.GET_DETAIL](courseId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: courseId,
    });
    return result?.data;
  }

  @Action({ rawError: true })
  async [CourseActions.DELETE](courseId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: courseId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseActions.GET_ALL_COURSE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseActions.GET_STRUCT](courseId) {
    // Get course detail
    const courseDetail = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: courseId,
    });
    // lesson
    let treeData = (courseDetail?.data as any)?.lesson;

    if (!(courseDetail?.isSuccess && treeData)) {
      console.error("Đã xảy ra lỗi khi get course detail");
    }
    treeData = JSON.parse(treeData);

    const courseLessons = await Captain.handle({
      action: ModuleAction.ALL,
      url: "lesson/course/" + courseId,
    });

    const lessons = courseLessons?.data;
    if (!(courseLessons?.isSuccess && Array.isArray(lessons))) {
      console.error("Đã xảy ra lỗi khi get course lessons");
    }

    const treeDataLesson = getTreeDataLesson(treeData, lessons);

    this.context.commit(CourseMutations.SET_TREE_DATA_LESSON, treeDataLesson);
  }

  @Action({ rawError: true })
  async [CourseActions.SET_STRUCT](par) {
    // Update struct
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `${this.uniqueKey}/struct`,
      body: {
        course_id: par.courseId,
        tree_data: par.treeData,
      },
    });

    if (!result?.isSuccess) {
      // commit sth ?
      console.log(result);
    }

    return result;
  }

  @Action({ rawError: true })
  async [CourseActions.ADD_COURSE_TO_CATE](params) {
    const result = await Captain.push({
      method: RequestMethod.GET,
      url: `${this.uniqueKey}/add-cate/${params.courseId}/${params.courseCateId}`,
    });

    if (!result?.isSuccess) {
      console.log(result);
    }
    return result;
  }
}
