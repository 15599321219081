import axios from "axios";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { SmsActions } from "./enums";

@Module
export default class SmsModule extends VuexModule {
  @Action({ rawError: true })
  async [SmsActions.GET_CONFIG]() {
    const url = process.env.VUE_APP_SMS_LISTENING_URL;
    const secret = process.env.VUE_APP_SMS_LISTENING_SECRET;
    return await axios.post(`${url}/config/getThienViet`, {
      secret,
    });
  }

  @Action({ rawError: true })
  async [SmsActions.SET_CONFIG](config) {
    const url = process.env.VUE_APP_SMS_LISTENING_URL;
    const secret = process.env.VUE_APP_SMS_LISTENING_SECRET;

    return await axios.post(`${url}/config/setThienViet`, {
      secret,
      ...config,
    });
  }
}
