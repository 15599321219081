enum NotificationActions {
  // action types
  GET_ALL_NOTIFICATION = "getAllNotification",
  CREATE = "createNotification",
  UPDATE = "updateNotification",
  DELETE = "delNotification",
}

enum NotificationMutations {
  // mutation types
  SET_LIST_NOTIFICATION = "setListNotification",
}

enum NotificationGetters {
  GET_BY_ID = "getNotificationByNotificationId",
}

export { NotificationActions, NotificationMutations, NotificationGetters };
