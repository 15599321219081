enum QuoteActions {
  // action types
  GET_ALL_QUOTE = "getAllQuote",
  GET_DETAIL = "getDetailQuote",
  CREATE = "createQuote",
  UPDATE = "updateQuote",
  DELETE = "delQuote",
}

enum QuoteMutations {
  // mutation types
  SET_LIST_QUOTE = "setListQuote",
}

enum QuoteGetters {
  GET_BY_ID = "getQuoteByQuoteId",
}

export { QuoteActions, QuoteMutations, QuoteGetters };
