enum ScheduleActions {
  // action types
  GET_ALL_SCHEDULE_BY_CLASS_ID = "getAllScheduleByClassId",
  CREATE = "createSchedule",
  UPDATE = "updateSchedule",
  DELETE = "delSchedule",
  GENERATE_SCHEDULE = "generateSchedule",
  GENERATE_ZOOM_MEETING = "generateZoomMeeting",
}

enum ScheduleMutations {
  // mutation types
  SET_LIST_SCHEDULE = "setListSchedule",
}

enum ScheduleGetters {
  GET_BY_ID = "getScheduleByScheduleId",
}

export { ScheduleActions, ScheduleMutations, ScheduleGetters };
