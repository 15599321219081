import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { People } from "../PeopleModule";
import { SearchPeopleConfig } from "../student/StudentModule";
import { TeacherActions, TeacherGetters, TeacherMutations } from "./enums";

export type Teacher = People & {
  zoomStatus?: ZoomAccountStatus;
  level?: string;
  order?: number;
  introduce?: string;
};
export interface TeacherState {
  totalCount: number;
  list: Partial<Teacher>[];
  uniqueKey: string;
  errors: Array<string>;
}

export interface ZoomUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
  role_id: string;
}

export enum ZoomAccountStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
  NOT_EXIST = "not_exist",
}

@Module
export default class TeacherModule extends VuexModule implements TeacherState {
  uniqueKey = "teacher";
  list: Teacher[] = [];
  totalCount = 0;
  errors = [];
  modalUpsertId = "modal-upsert-teacher";

  get [TeacherGetters.GET_BY_ID]() {
    return (teacherId: number) => {
      const detail = this.list.filter((m) => m.id === teacherId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [TeacherMutations.SET_LIST_TEACHER](list: Teacher[]) {
    this.list = list;
  }

  @Mutation
  [TeacherMutations.SET_TOTAL_COUNT_TEACHER](totalCount: number) {
    this.totalCount = totalCount;
  }

  @Action({ rawError: true })
  async [TeacherActions.GET_LIST_TEACHER]() {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: "people/teacher?type=admin",
    });

    const getZoomUser = await Captain.push({
      method: RequestMethod.GET,
      url: "zoom/users",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      const teachers: Partial<Teacher>[] = result?.data;
      if (getZoomUser.isSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const zoomUser: ZoomUser[] = (getZoomUser.data as any)?.users;
        const zoomEmailByStatus: Record<string, string> = {};
        if (zoomUser.length > 0) {
          zoomUser.map((m) => {
            zoomEmailByStatus[m.email] = m.status;
          });
        }

        teachers.map((m) => {
          const email = m.email;
          if (email && zoomEmailByStatus[email]) {
            switch (zoomEmailByStatus[email]) {
              case "active":
                m.zoomStatus = ZoomAccountStatus.ACTIVE;
                break;
              case "pending":
                m.zoomStatus = ZoomAccountStatus.PENDING;
                break;
              case "inactive":
                m.zoomStatus = ZoomAccountStatus.INACTIVE;
                break;
            }
          } else {
            m.zoomStatus = ZoomAccountStatus.NOT_EXIST;
          }
        });
      }

      this.context.commit(TeacherMutations.SET_LIST_TEACHER, teachers);

      this.context.commit(
        TeacherMutations.SET_TOTAL_COUNT_TEACHER,
        result?.data.length
      );
    }
  }

  @Action({ rawError: true })
  async [TeacherActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: "people",
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(TeacherActions.GET_LIST_TEACHER);
    }
    return result;
  }

  @Action({ rawError: true })
  async [TeacherActions.DELETE](teacherId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: "people",
      id: teacherId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(TeacherActions.GET_LIST_TEACHER);
    }
    return result;
  }

  @Action({ rawError: true })
  async [TeacherActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL](
    searchConfig: SearchPeopleConfig
  ) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `people/findPeopleByTypesAndKeyword?peopleTypes[]=teacher&keyword=${searchConfig.keyword}`,
    });

    const getZoomUser = await Captain.push({
      method: RequestMethod.GET,
      url: "zoom/users",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      const teachers: Partial<Teacher>[] = result?.data;
      if (getZoomUser.isSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const zoomUser: ZoomUser[] = (getZoomUser.data as any)?.users;
        const zoomEmailByStatus: Record<string, string> = {};
        if (zoomUser.length > 0) {
          zoomUser.map((m) => {
            zoomEmailByStatus[m.email] = m.status;
          });
        }

        teachers.map((m) => {
          const email = m.email;
          if (email && zoomEmailByStatus[email]) {
            switch (zoomEmailByStatus[email]) {
              case "active":
                m.zoomStatus = ZoomAccountStatus.ACTIVE;
                break;
              case "pending":
                m.zoomStatus = ZoomAccountStatus.PENDING;
                break;
              case "inactive":
                m.zoomStatus = ZoomAccountStatus.INACTIVE;
                break;
            }
          } else {
            m.zoomStatus = ZoomAccountStatus.NOT_EXIST;
          }
        });
      }

      this.context.commit(TeacherMutations.SET_LIST_TEACHER, teachers);

      this.context.commit(
        TeacherMutations.SET_TOTAL_COUNT_TEACHER,
        result?.data.length
      );
    }
  }
}
