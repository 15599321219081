enum NewsActions {
  // action types
  GET_NEWS_LIST = "getNewsList",
  GET_DETAIL = "getDetailNews",
  CREATE = "createNews",
  UPDATE = "updateNews",
  DELETE = "delNews",
}

enum NewsMutations {
  // mutation types
  SET_LIST_NEWS = "setListNews",
  SET_PAGINATION = "setPaginationNews",
  SET_TEXT_SEARCH = "setTextSearchNews",
}

enum NewsGetters {
  GET_BY_ID = "getNewsByNewsId",
}

export { NewsActions, NewsMutations, NewsGetters };
