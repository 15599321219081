import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BrandActions, BrandGetters, BrandMutations } from "./enums";

export interface Brand {
  name: string;
  description: string;
  brand_id: number;
  status: string;
  logo: string;
}

export interface BrandState {
  list: Partial<Brand>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class BrandModule extends VuexModule implements BrandState {
  uniqueKey = "brand";
  list: Brand[] = [];
  errors = [];
  modalUpsertId = "modal-upsert-brand";

  get [BrandGetters.GET_BY_ID]() {
    return (brandId: number) => {
      const detail = this.list.filter((m) => m.brand_id === brandId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [BrandMutations.SET_LIST_BRAND](list: Brand[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [BrandActions.GET_ALL_BRAND]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(BrandMutations.SET_LIST_BRAND, result?.data);
    }
  }

  @Action({ rawError: true })
  async [BrandActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BrandActions.GET_ALL_BRAND);
    }
    return result;
  }

  @Action({ rawError: true })
  async [BrandActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.brand_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BrandActions.GET_ALL_BRAND);
    }
    return result;
  }

  @Action({ rawError: true })
  async [BrandActions.DELETE](brandId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: brandId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BrandActions.GET_ALL_BRAND);
    }
    return result;
  }
}
