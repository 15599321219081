/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertTreeDataToMapObj = (
  treeData,
  currentPath,
  informationByLessonId
) => {
  currentPath = currentPath || "";
  informationByLessonId = informationByLessonId || {};
  for (let i = 0; i < treeData.length; i++) {
    const mono = treeData[i];
    const lessonId = mono["lessonId"];
    const children = mono["children"];

    if (lessonId) {
      const targetPath = currentPath + (i + 1) + ".";
      informationByLessonId[lessonId] = {
        path: targetPath,
        level: getPathLevel(targetPath),
      };

      if (children && children.length > 0) {
        informationByLessonId = convertTreeDataToMapObj(
          children,
          targetPath,
          informationByLessonId
        );
      }
    }
  }

  return informationByLessonId;
};

export const getTreeDataLesson = (treeData: any[], lessons) => {
  if (treeData.length > 0) {
    const lessonNameById = {};
    lessons.map((lesson) => {
      lessonNameById[lesson.lesson_id] = lesson.name;
    });

    return fillTreeDataWithLessonName(treeData, lessonNameById);
  }

  const newTreeData: Record<string, any>[] = [];
  lessons.map((lesson) => {
    newTreeData.push({
      lessonId: lesson.lesson_id,
      text: lesson.name,
    });
  });
  return newTreeData;
};

export const fillTreeDataWithLessonName = (treeData, lessonNameById) => {
  for (let i = 0; i < treeData.length; i++) {
    const mono = treeData[i];
    const lessonId = mono["lessonId"];
    mono["text"] = lessonNameById[lessonId] || "";
    let children = mono["children"];
    if (children && children.length > 0) {
      children = fillTreeDataWithLessonName(children, lessonNameById);
    }
  }
  return treeData;
};

export const clearTreeDataWithLessonName = (treeData) => {
  for (let i = 0; i < treeData.length; i++) {
    const mono = treeData[i];
    let children = mono["children"];
    delete mono["text"];
    if (children && children.length > 0) {
      children = clearTreeDataWithLessonName(children);
    }
  }
  return treeData;
};

export const getPathLevel = (path) => {
  return (path.match(/\./g) || []).length;
};
