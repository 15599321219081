enum SiriActions {
  // action types
  GET_ALL_SIRI = "getAllSiri",
  CREATE = "createSiri",
  UPDATE = "updateSiri",
  DELETE = "delSiri",
}

enum SiriMutations {
  // mutation types
  SET_LIST_SIRI = "setListSiri",
}

enum SiriGetters {
  GET_BY_ID = "getSiriBySiriId",
}

export { SiriActions, SiriMutations, SiriGetters };
