enum PeopleActions {
  // action types
  GET_ALL_PEOPLE = "getAllPeople",
  GET_ALL_TEACHER = "getAllTeacher",
  GET_ALL_STUDENT = "getAllStudent",
  GET_ALL_EMPLOYEE = "getAllEmployee",
  GET_ALL_GUEST = "getAllGuest",
  CREATE = "createPeople",
  UPDATE = "updatePeople",
  DELETE = "delPeople",
}

enum PeopleMutations {
  // mutation types
  SET_LIST_PEOPLE = "setListPeople",
}

enum PeopleGetters {
  GET_BY_ID = "getPeopleByPeopleId",
}

export { PeopleActions, PeopleMutations, PeopleGetters };
