enum BrandActions {
  // action types
  GET_ALL_BRAND = "getAllBrand",
  CREATE = "createBrand",
  UPDATE = "updateBrand",
  DELETE = "delBrand",
}

enum BrandMutations {
  // mutation types
  SET_LIST_BRAND = "setListBrand",
}

enum BrandGetters {
  GET_BY_ID = "getBrandByBrandId",
}

export { BrandActions, BrandMutations, BrandGetters };
