import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "axios";
import Logger from "@/core/services/LoggerService";

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface CaptainRequestConfig {
  url: string;
  method: RequestMethod;
  headers?: Record<string, unknown>;

  params?: Record<string, unknown>;
  body?: Record<string, unknown>;
}

export enum ModuleAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DETAIL = "DETAIL",
  LIST = "LIST",
  DELETE = "DELETE",
  ALL = "ALL",
}
export interface CaptainModuleAction {
  action: ModuleAction;
  url?: string;
  id?: string | number;
  data?: Record<string, unknown>;
  limit?: number;
  page?: number;
}

export interface StepResult {
  isSuccess: boolean;
  data?: unknown;
  metadata?: {
    limit?: number;
    total_page?: number;
    current_page?: number;
    sort?: Record<string, string>;
    count: number;
    total_item?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  headers?: Record<string, unknown>;
  error?: unknown;
}

export enum ResponseStatusCode {
  SUCCESS = 1000,
  ERROR = 1001,
}

export interface ResponseDto {
  status: ResponseStatusCode;
  data?: unknown;
  metadata?: {
    limit?: number;
    total_page?: number;
    current_page?: number;
    sort?: Record<string, string>;
    count: number;
    total_item?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  message?: unknown;
}

@Module
export default class Captain extends VuexModule {
  static baseUrl = process.env.VUE_APP_BACKEND_URL;
  static keyAccessToken = "vm-token";
  static accessToken: unknown;

  static setHeader(headers: Record<string, unknown>): Record<string, unknown> {
    headers[this.keyAccessToken] = localStorage.getItem("vm-token");
    return headers;
  }

  @Mutation
  public static loadAccessTokenFromLocalStorage() {
    this.accessToken = localStorage.getItem(this.keyAccessToken);
  }

  @Mutation
  public static setAccessTokenToLocalStorage(accessToken) {
    localStorage.setItem(this.keyAccessToken, accessToken);
  }

  @Action({ rawError: true })
  public static async push(params: CaptainRequestConfig): Promise<StepResult> {
    const endpoint = this.baseUrl + params.url;
    const headers = this.setHeader(params.headers || {});

    let response: AxiosResponse;
    switch (params.method) {
      case RequestMethod.GET:
        response = await this.get(endpoint, {
          headers,
          params: params.params,
        });
        break;
      case RequestMethod.POST:
        response = await this.post(endpoint, params.body || {}, {
          headers,
        });
        break;
      case RequestMethod.PATCH:
        response = await this.patch(endpoint, params.body || {}, {
          headers,
        });
        break;
      case RequestMethod.DELETE:
        response = await this.delete(endpoint, {
          headers,
        });
        break;
      default:
        Logger.exception(`Unknown RequestMethod: ${params.method}`);
        return {
          isSuccess: false,
          error: `Unknown RequestMethod: ${params.method}`,
        };
    }

    const body: ResponseDto = response?.data;
    const isSuccess =
      response.status < 300 && body?.status === ResponseStatusCode.SUCCESS;

    return {
      isSuccess,
      data: body?.data,
      headers: response?.headers,
      metadata: body?.metadata,
    };
  }

  public static async get(resource: string, params: AxiosRequestConfig) {
    const res = await axios.get(resource, {
      headers: this.setHeader(params.headers),
      params: params.params,
    });
    return res;
  }

  public static async post(
    resource: string,
    body: Record<string, unknown>,
    params: AxiosRequestConfig
  ) {
    return await axios.post(resource, body, {
      headers: this.setHeader(params.headers),
    });
  }

  public static async patch(
    resource: string,
    body: Record<string, unknown>,
    params: AxiosRequestConfig
  ) {
    return await axios.patch(resource, body, {
      headers: this.setHeader(params.headers),
    });
  }

  public static async delete(resource: string, params: AxiosRequestConfig) {
    return await axios.delete(resource, {
      headers: this.setHeader(params.headers),
    });
  }

  public static async handle(captainAction: CaptainModuleAction) {
    switch (captainAction.action) {
      case ModuleAction.CREATE:
        return await this.push({
          method: RequestMethod.POST,
          body: captainAction.data || {},
          url: captainAction.url || "",
        });

      case ModuleAction.ALL: {
        const params = Object.assign(
          {
            page: captainAction.page,
            limit: captainAction.limit,
          },
          captainAction.data || {}
        );

        return await this.push({
          method: RequestMethod.GET,
          url: captainAction.url || "",
          params: params,
        });
      }

      case ModuleAction.DETAIL:
        return await this.push({
          method: RequestMethod.GET,
          url: `${captainAction.url || ""}/${captainAction.id}`,
        });

      case ModuleAction.UPDATE:
        return await this.push({
          method: RequestMethod.PATCH,
          body: captainAction.data || {},
          url: `${captainAction.url || ""}/${captainAction.id}`,
        });

      case ModuleAction.LIST:
        return await this.push({
          method: RequestMethod.POST,
          body: captainAction.data || {},
          url: captainAction.url || "",
        });
      case ModuleAction.DELETE:
        if (!captainAction.id) {
          console.warn("captainAction.id is not set!");
        }
        return await this.push({
          method: RequestMethod.DELETE,
          url: `${captainAction.url || ""}/${captainAction.id}`,
        });
    }
  }
}
