import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  NotificationActions,
  NotificationGetters,
  NotificationMutations,
} from "./enums";

export interface Notification {
  name: string;
  description: string;
  notification_id: number;
  status: string;
  logo: string;
}

export interface NotificationState {
  list: Partial<Notification>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class NotificationModule
  extends VuexModule
  implements NotificationState
{
  uniqueKey = "notification";
  list: Notification[] = [];
  errors = [];
  modalUpsertId = "modal-upsert-notification";

  get [NotificationGetters.GET_BY_ID]() {
    return (notificationId: number) => {
      const detail = this.list.filter(
        (m) => m.notification_id === notificationId
      );
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [NotificationMutations.SET_LIST_NOTIFICATION](list: Notification[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [NotificationActions.GET_ALL_NOTIFICATION]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(
        NotificationMutations.SET_LIST_NOTIFICATION,
        result?.data
      );
    }
  }

  @Action({ rawError: true })
  async [NotificationActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess) {
      this.context.dispatch(NotificationActions.GET_ALL_NOTIFICATION);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NotificationActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.notification_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NotificationActions.GET_ALL_NOTIFICATION);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NotificationActions.DELETE](notificationId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: notificationId,
    });

    if (result?.isSuccess) {
      this.context.dispatch(NotificationActions.GET_ALL_NOTIFICATION);
    }
    return result;
  }
}
