export enum GuestActions {
  // action types
  GET_LIST_GUEST = "getListGuest",
  CREATE = "createGuest",
  UPDATE = "updateGuest",
  DELETE = "delGuest",
  SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL = "searchByNameOrIdOrPhoneOrEmail",
}

export enum GuestMutations {
  // mutation types
  SET_LIST_GUEST = "setListGuest",
  SET_TOTAL_COUNT_GUEST = "setTotalCountGuest",
}

export enum GuestGetters {
  GET_BY_ID = "getGuestByGuestId",
}
