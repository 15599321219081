enum NewsCateActions {
  // action types
  GET_ALL_NEWS_CATE = "getAllNewsCate",
  GET_ALL_NEWS_BY_CATE_ID = "getAllNewsByCateId",
  GET_DETAIL = "getNewsCateDetail",
  CREATE = "createNewsCate",
  UPDATE = "updateNewsCate",
  DELETE = "delNewsCate",
  REMOVE_NEWS_FROM_CATE = "removeNewsFromCate",
  UPDATE_DETAIL_POINT = "UpdateNewsCateDetailPoint",
}

enum NewsCateMutations {
  // mutation types
  SET_LIST_NEWS_CATE = "setListNewsCate",
  SET_LIST_NEWS_CATE_DETAIL = "SET_LIST_NEWS_CATE_DETAIL",
}

enum NewsCateGetters {
  GET_BY_ID = "getNewsCateByNewsCateId",
  GET_ALL_CACHE_INFO = "getNewsCateCache",
  GET_NEWS_CATE_DETAIL_BY_ID = "getNewsCateDetailById",
}

export { NewsCateActions, NewsCateMutations, NewsCateGetters };
