import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BranchActions, BranchGetters, BranchMutations } from "./enums";

export interface Branch {
  name: string;
  address: string;
  branch_id: number;
  status: string;
}

export interface BranchState {
  list: Partial<Branch>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class BranchModule extends VuexModule implements BranchState {
  uniqueKey = "branch";
  list: Branch[] = [
    {
      branch_id: 1,
      address: "Loading...",
      name: "Loading...",
      status: "active",
    },
  ];
  errors = [];
  modalUpsertId = "modal-upsert-branch";

  get [BranchGetters.GET_BY_ID]() {
    return (branchId: number) => {
      const detail = this.list.filter((m) => m.branch_id === branchId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [BranchMutations.SET_LIST_BRANCH](list: Branch[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [BranchActions.GET_ALL_BRANCH]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(BranchMutations.SET_LIST_BRANCH, result?.data);
    }
  }

  @Action({ rawError: true })
  async [BranchActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BranchActions.GET_ALL_BRANCH);
    }
    return result;
  }

  @Action({ rawError: true })
  async [BranchActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.branch_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BranchActions.GET_ALL_BRANCH);
    }
    return result;
  }

  @Action({ rawError: true })
  async [BranchActions.DELETE](branchId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: branchId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(BranchActions.GET_ALL_BRANCH);
    }
    return result;
  }
}
