import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ClassActions, ClassGetters, ClassMutations } from "./enums";

export enum ClassPublicStatus {
  UNPUBLIC = 0,
  PUBLIC = 1,
}

export enum ClassStatus {
  // Đang tuyển sinh
  EXPECTED = 0, // Lịch dự kiến
  OFFICIAL = 1, // Lịch chính thức

  // Đang học
  OPENNING = 10, // Đang học
  COMPLETED = 11, // Đã kết thúc
}

export interface Class {
  class_id: number;
  course_id: number;
  code: string;
  name: string;
  branch_id: number;
  start_date: string;
  end_date: string;
  teacher_id: string;
  sub_teacher_id: string;
  is_public: ClassPublicStatus;
  status: ClassStatus;
  statusLabel?: string;
  number_session: number;
  number_student: number;
  holiday: string;
  schedule_config: string;
  created_at: string;
  updated_at: string;
}

export interface ClassState {
  list: Partial<Class>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class ClassModule extends VuexModule implements ClassState {
  uniqueKey = "class";
  list: Class[] = [];
  errors = [];

  get [ClassGetters.GET_BY_ID]() {
    return (classId: number) => {
      const detail = this.list.filter((m) => m.class_id === classId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [ClassMutations.SET_LIST_CLASS](list: Class[]) {
    list.map((m) => {
      switch (m.status) {
        case ClassStatus.EXPECTED:
          m.statusLabel = "Dự kiến";
          break;
        case ClassStatus.OFFICIAL:
          m.statusLabel = "Chính thức";
          break;
        case ClassStatus.OPENNING:
          m.statusLabel = "Đang học";
          break;
        case ClassStatus.COMPLETED:
          m.statusLabel = "Đã kết thúc";
          break;
      }
    });
    this.list = list;
  }

  @Action({ rawError: true })
  async [ClassActions.GET_ALL_CLASS]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(ClassMutations.SET_LIST_CLASS, result?.data);
    }
  }

  @Action({ rawError: true })
  async [ClassActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(ClassActions.GET_ALL_CLASS);
    }
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.class_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(ClassActions.GET_ALL_CLASS);
    }
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.GET_DETAIL](classId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: classId,
    });
    console.log(result);
    return result?.data;
  }

  @Action({ rawError: true })
  async [ClassActions.GET_REPORT_ATTENDANCE](classId) {
    const result = await Captain.push({
      url: `attendance/report_class/${classId}`,
      method: RequestMethod.POST,
    });
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.GET_JSON_DATA_REPORT_ATTENDANCE](classId) {
    const result = await Captain.push({
      url: `attendance/export_json_to_csv/${classId}`,
      method: RequestMethod.POST,
    });
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.DELETE](classId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: classId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(ClassActions.GET_ALL_CLASS);
    }
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.GET_LIST_STUDENT](classId) {
    // get all page
    let page = 1;
    const limit = 100;
    let hasNextPage = true;
    let totalStudents = [];

    while (hasNextPage) {
      const result = await Captain.push({
        method: RequestMethod.PATCH,
        url: `class/student/${classId}?page=${page}&limit=${limit}`,
      });

      if (result.isSuccess) {
        totalStudents = totalStudents.concat(result.data as any);
      } else {
        console.log("break ...");
        break;
      }

      hasNextPage = result.metadata?.count === result.metadata?.limit;
      page++;
    }

    return {
      isSuccess: true,
      data: totalStudents,
    };
  }

  @Action({ rawError: true })
  async [ClassActions.ADD_STUDENT_TO_CLASS](params) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `class/student/${params.classId}/${params.studentId}`,
    });
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.REMOVE_STUDENT_FROM_CLASS](params) {
    const result = await Captain.push({
      method: RequestMethod.DELETE,
      url: `class/student/${params.classId}/${params.studentId}`,
    });
    return result;
  }

  @Action({ rawError: true })
  async [ClassActions.BAND_STUDENT_FROM_CLASS](params) {
    const result = await Captain.push({
      method: RequestMethod.DELETE,
      url: `class/student/${params.classId}/${params.studentId}/band`,
    });
    return result;
  }
}
