import { notifyCommonResponse } from "@/core/helpers/common";
import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { EventEduActions, EventEduGetters, EventEduMutations } from "./enums";

export interface EventEdu {
  event_id: number;
  name: string;
  cover: string;
  type: string;
  location: string;
  content: string;
  start_time: string;
  end_time: string;
  open_before: number;
  status: string;
  point: number;
}

export interface EventEduState {
  list: Partial<EventEdu>[];
  uniqueKey: string;
  errors: Array<string>;
}

export interface CreateZoomMeetingManualQueryDto {
  zoomMeetingId: string;
  zoomMeetingPwd: string;
  eventEduId?: number;
}

@Module
export default class EventEduModule
  extends VuexModule
  implements EventEduState
{
  uniqueKey = "event-edu";
  list: EventEdu[] = [];
  errors = [];

  get [EventEduGetters.GET_BY_ID]() {
    return (eventEduId: number) => {
      const detail = this.list.filter((m) => m.event_id === eventEduId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [EventEduMutations.SET_LIST_EVENT_EDU](list: EventEdu[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [EventEduActions.GET_ALL_EVENT_EDU]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(EventEduMutations.SET_LIST_EVENT_EDU, result?.data);
    }
  }

  @Action({ rawError: true })
  async [EventEduActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    }
    return result;
  }

  @Action({ rawError: true })
  async [EventEduActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.event_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    }
    return result;
  }

  @Action({ rawError: true })
  async [EventEduActions.GET_DETAIL](eventEduId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: eventEduId,
    });
    return result?.data;
  }

  @Action({ rawError: true })
  async [EventEduActions.DELETE](eventEduId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: eventEduId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    }
    return result;
  }

  @Action({ rawError: true })
  async [EventEduActions.CREATE_ZOOM_MEETING_AUTO](eventEduId) {
    const result = await Captain.push({
      method: RequestMethod.GET,
      url: `${this.uniqueKey}/${eventEduId}/createZoomMeeting`,
    });
    notifyCommonResponse(result);
    await this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    return result;
  }

  @Action({ rawError: true })
  async [EventEduActions.CREATE_ZOOM_MEETING_MANUAL](
    params: CreateZoomMeetingManualQueryDto
  ) {
    const result = await Captain.push({
      method: RequestMethod.GET,
      url: `${this.uniqueKey}/${params.eventEduId}/createZoomMeetingManual`,
      params: params as any,
    });
    notifyCommonResponse(result);
    if (result.isSuccess) {
      await this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    }
    return result;
  }

  @Action({ rawError: true })
  async [EventEduActions.DEL_ZOOM_MEETING](eventEduId) {
    const result = await Captain.push({
      method: RequestMethod.GET,
      url: `${this.uniqueKey}/${eventEduId}/delZoomMeeting`,
    });

    notifyCommonResponse(result);
    await this.context.dispatch(EventEduActions.GET_ALL_EVENT_EDU);
    return result;
  }
}
