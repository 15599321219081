enum AccountActions {
  // action types
  GET_ALL_ACCOUNT = "getAllAccount",
  CREATE = "createAccount",
  UPDATE = "updateAccount",
  DELETE = "delAccount",
  CHECK_USERNAME_EXIST = "checkUsernameExist",
}

enum AccountMutations {
  // mutation types
  SET_LIST_ACCOUNT = "setListAccount",
}

enum AccountGetters {
  GET_BY_ID = "getAccountByAccountId",
}

export { AccountActions, AccountMutations, AccountGetters };
