enum VideoActions {
  // action types
  GET_ALL_VIDEO = "getAllVideo",
  GET_DETAIL_VIDEO = "getDetailVideo",
  CREATE = "createVideo",
  UPDATE = "updateVideo",
  DELETE = "delVideo",
}

enum VideoMutations {
  // mutation types
  SET_LIST_VIDEO = "setListVideo",
  SET_VIDEO_ID_TARGET_UPSERT = "setVideoIdTargetUpsert",
}

enum VideoGetters {
  GET_BY_ID = "getVideoByVideoId",
}

export { VideoActions, VideoMutations, VideoGetters };
