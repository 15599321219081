import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  CourseCateActions,
  CourseCateGetters,
  CourseCateMutations,
} from "./enums";

export interface CourseCate {
  cate_id: number;
  name: string;
  desp: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface CourseCateDetail {
  detail_cate_id: number;
  id: number; // this is course id
  cate_id: number;
  point: number;
}

export interface CourseCateState {
  list: Partial<CourseCate>[];
  listDetail: Partial<CourseCateDetail>[];
  listDetailByDetailId: Record<number, CourseCateDetail>;
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class CourseCateModule
  extends VuexModule
  implements CourseCateState
{
  uniqueKey = "course-cate";
  list: CourseCate[] = [];
  listDetail: CourseCateDetail[] = [];

  // List này có thể chứa thông tin chi tiết của tất cả course cate mà ko bị trùng nhau
  listDetailByDetailId: Record<number, CourseCateDetail> = {};

  errors = [];
  modalUpsertId = "modal-upsert-course-cate";
  modalUpdatePointId = "modal-update-point-in-course-cate";
  modalInsertCourseId = "modal-insert-course-to-cate";

  get [CourseCateGetters.GET_BY_ID]() {
    return (courseCateId: number) => {
      const detail = this.list.filter((m) => m.cate_id === courseCateId);
      return detail.length ? detail[0] : {};
    };
  }

  get [CourseCateGetters.GET_ALL_CACHE_INFO]() {
    return () => {
      return this.list;
    };
  }

  get [CourseCateGetters.GET_COURSE_CATE_DETAIL_BY_ID]() {
    return (courseCateDetailId: number) => {
      const detail = this.listDetailByDetailId[courseCateDetailId];
      return detail || {};
    };
  }

  @Mutation
  [CourseCateMutations.SET_LIST_COURSE_CATE](list: CourseCate[]) {
    this.list = list;
  }

  @Mutation
  [CourseCateMutations.SET_LIST_COURSE_CATE_DETAIL](list: CourseCateDetail[]) {
    this.listDetail = list;
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.detail_cate_id) {
        this.listDetailByDetailId[element.detail_cate_id] = element;
      }
    }
  }

  @Action({ rawError: true })
  async [CourseCateActions.GET_ALL_COURSE_CATE]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(
        CourseCateMutations.SET_LIST_COURSE_CATE,
        result?.data
      );
    }
  }

  @Action({ rawError: true })
  async [CourseCateActions.GET_ALL_COURSE_BY_CATE_ID](par) {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: `course/category/${par.id}`,
      data: {
        page: par?.page || 1,
      },
    });

    if (result?.isSuccess) {
      this.context.commit(
        CourseCateMutations.SET_LIST_COURSE_CATE_DETAIL,
        result?.data
      );

      return result.data;
    }
  }

  @Action({ rawError: true })
  async [CourseCateActions.GET_DETAIL](cateId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: cateId,
    });
    if (result?.isSuccess) {
      return result.data;
    }
  }

  @Action({ rawError: true })
  async [CourseCateActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseCateActions.GET_ALL_COURSE_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseCateActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.cate_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseCateActions.GET_ALL_COURSE_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseCateActions.UPDATE_DETAIL_POINT](par) {
    const result = await Captain.push({
      url: `course/point/${par.detail_cate_id}?point=${par.point}`,
      method: RequestMethod.GET,
    });
    return result;
  }

  @Action({ rawError: true })
  async [CourseCateActions.DELETE](courseCateId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: courseCateId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(CourseCateActions.GET_ALL_COURSE_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [CourseCateActions.REMOVE_COURSE_FROM_CATE](detailCateId) {
    const result = await Captain.push({
      url: `course/remove-cate/${detailCateId}`,
      method: RequestMethod.GET,
    });
    return result;
  }
}
