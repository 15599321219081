import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PeopleActions, PeopleGetters, PeopleMutations } from "./enums";

export interface People {
  id: number;
  type: PeopleType;
  department_id: number;
  branch_id: number;
  manager_id: number;
  nickname: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  email: string;
  phone: string;
  phone_work: string;
  gender: string;
  status: string;
  start_date: string;
  created_time: string;
  updated_time: string;
  city: string;
  district: string;
  commune: string;
  address: string;
  avatar: string;
  description: string;
  education: string;
}

export interface PeopleState {
  list: Partial<People>[];
  uniqueKey: string;
  errors: Array<string>;
}

export enum PeopleType {
  TEACHER = "teacher",
  STUDENT = "student",
  EMPLOYEE = "employee",
  GUEST = "guest",
}

@Module
export default class PeopleModule extends VuexModule implements PeopleState {
  uniqueKey = "people";
  list: People[] = [];
  errors = [];
  modalUpsertId = "modal-upsert-people";

  get [PeopleGetters.GET_BY_ID]() {
    return (peopleId: number) => {
      const detail = this.list.filter((m) => m.id === peopleId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [PeopleMutations.SET_LIST_PEOPLE](list: People[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [PeopleActions.GET_ALL_PEOPLE](peopleType: PeopleType) {
    const query = {
      limit: 3,
      page: 1,
      filter: [
        {
          column: "type",
          type: "exact",
          value: peopleType,
        },
      ],
      sort: [
        {
          column: "id",
          type: "DESC",
        },
      ],
    };

    const result = await Captain.push({
      method: RequestMethod.POST,
      body: query,
      url: "/people/list",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(PeopleMutations.SET_LIST_PEOPLE, result?.data);
    }
  }

  @Action({ rawError: true })
  async [PeopleActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(PeopleActions.GET_ALL_PEOPLE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [PeopleActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.people_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(PeopleActions.GET_ALL_PEOPLE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [PeopleActions.DELETE](peopleId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: peopleId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(PeopleActions.GET_ALL_PEOPLE);
    }
    return result;
  }
}
