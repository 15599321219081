import { generateRandomString } from "@/core/helpers/documentation";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { VideoActions, VideoGetters, VideoMutations } from "./enums";

export interface Video {
  videoId: string;
  src: number;
  name: string;
  order: number;
  defaultTime: number;
  min: number;
  max: number;
}

export interface VideoState {
  list: Partial<Video>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class VideoModule extends VuexModule implements VideoState {
  uniqueKey = "video";
  list: Video[] = [];
  errors = [];
  videoIdTargetUpsert = "";

  get [VideoGetters.GET_BY_ID]() {
    return (id: string) => {
      const detail = this.list.filter((m) => m.videoId === id);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [VideoMutations.SET_VIDEO_ID_TARGET_UPSERT](videoId) {
    this.videoIdTargetUpsert = videoId;
  }

  @Mutation
  [VideoMutations.SET_LIST_VIDEO](list: Video[]) {
    list.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = list;
  }

  @Mutation
  async [VideoActions.CREATE](par) {
    const notRef = par;
    const newId = generateRandomString(8);

    notRef.videoId = newId;
    const videoList = this.list;
    videoList.push(notRef);

    videoList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });
    this.list = videoList;
  }

  @Mutation
  async [VideoActions.UPDATE](par: Video) {
    const videoId = par.videoId;
    const videoList = this.list;
    for (let index = 0; index < videoList.length; index++) {
      const element = videoList[index];
      if (element.videoId === videoId) {
        videoList[index] = par;
        break;
      }
    }

    videoList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = videoList;
  }

  @Mutation
  async [VideoActions.DELETE](videoId) {
    const videoList = this.list;
    for (let index = 0; index < videoList.length; index++) {
      const element = videoList[index];
      if (element.videoId === videoId) {
        videoList.splice(index, 1);
        break;
      }
    }

    videoList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = videoList;
  }
}
