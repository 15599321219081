import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UploadState {
  uniqueKey: string;
  errors: Array<string>;
}

export interface ConfigUpload {
  name: string;
  exp: string;
  class: string;
  hmac: string;
  extra: string;
}

@Module
export default class UploadModule extends VuexModule implements UploadState {
  uniqueKey = "upload";
  errors = [];

  @Action({ rawError: true })
  async getTokenUpload(par) {
    const typeMedia = par.type; // audio, image
    const classMedia = par.class; // cover, avatar

    const result = await Captain.push({
      method: RequestMethod.POST,
      body: {
        class: classMedia,
        type: typeMedia,
      },
      url: "upload/token",
    });
    console.log(result);

    if (result?.isSuccess) {
      return result?.data;
    }
    return false;
  }
}
