import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { QuoteActions, QuoteGetters, QuoteMutations } from "./enums";

export interface Quote {
  id: number;
  quote: string;
  image: string;
  point: number;
  created_at: string;
  updated_at: string;
}

export interface QuoteState {
  list: Partial<Quote>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class QuoteModule extends VuexModule implements QuoteState {
  uniqueKey = "quote";
  list: Quote[] = [];
  errors = [];

  get [QuoteGetters.GET_BY_ID]() {
    return (quoteId: number) => {
      const detail = this.list.filter((m) => m.id === quoteId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [QuoteMutations.SET_LIST_QUOTE](list: Quote[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [QuoteActions.GET_ALL_QUOTE]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(QuoteMutations.SET_LIST_QUOTE, result?.data);
    }
  }

  @Action({ rawError: true })
  async [QuoteActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(QuoteActions.GET_ALL_QUOTE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [QuoteActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(QuoteActions.GET_ALL_QUOTE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [QuoteActions.GET_DETAIL](quoteId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: quoteId,
    });
    console.log(result);
    return result?.data;
  }

  @Action({ rawError: true })
  async [QuoteActions.DELETE](quoteId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: quoteId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(QuoteActions.GET_ALL_QUOTE);
    }
    return result;
  }
}
