import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import HttpModule from "@/store/captain/CaptainModule";
import BranchModule from "./vm/branch/BranchModule";
import BrandModule from "./vm/brand/BrandModule";
import TeacherModule from "./vm/people/teacher/TeacherModule";
import PeopleModule from "./vm/people/PeopleModule";
import UploadModule from "./upload/UploadModule";
import StaticRoomModule from "./vm/static-room/StaticRoomModule";
import StudentModule from "./vm/people/student/StudentModule";
import GuestModule from "./vm/people/guest/GuestModule";
import EmployeeModule from "./vm/people/employee/EmployeeModule";
import AccountModule from "./vm/account/AccountModule";
import NewsModule from "./vm/news/NewsModule";
import NewsCateModule from "./vm/news-cate/NewsCateModule";
import AudioModule from "./vm/static-room/media/audio/AudioModule";
import VideoModule from "./vm/static-room/media/video/VideoModule";
import CourseModule from "./vm/course/CourseModule";
import LessonModule from "./vm/lesson/LessonModule";
import QuoteModule from "./vm/quote/QuoteModule";
import ClassModule from "./vm/class/ClassModule";
import ScheduleModule from "./vm/schedule/ScheduleModule";
import EventEduModule from "./vm/event-edu/EventEduModule";
import NotificationModule from "./vm/notification/NotificationModule";
import SiriModule from "./vm/siri/SiriModule";
import CourseCateModule from "./vm/course-cate/CourseCateModule";
import SmsModule from "./vm/sms/SmsModule";
import ReportModule from "./vm/report/ReportModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    HttpModule,
    BranchModule,
    BrandModule,
    PeopleModule,
    TeacherModule,
    StudentModule,
    GuestModule,
    EmployeeModule,
    CourseModule,
    UploadModule,
    StaticRoomModule,
    AccountModule,
    NewsModule,
    NewsCateModule,
    AudioModule,
    VideoModule,
    LessonModule,
    QuoteModule,
    ClassModule,
    ScheduleModule,
    EventEduModule,
    NotificationModule,
    SiriModule,
    CourseCateModule,
    SmsModule,
    ReportModule,
  },
});

export default store;
