enum CourseCateActions {
  // action types
  GET_ALL_COURSE_CATE = "getAllCourseCate",
  GET_ALL_COURSE_BY_CATE_ID = "getAllCourseByCateId",
  GET_DETAIL = "getCourseCateDetail",
  CREATE = "createCourseCate",
  UPDATE = "updateCourseCate",
  DELETE = "delCourseCate",
  REMOVE_COURSE_FROM_CATE = "removeCourseFromCate",
  UPDATE_DETAIL_POINT = "UpdateCourseCateDetailPoint",
}

enum CourseCateMutations {
  // mutation types
  SET_LIST_COURSE_CATE = "setListCourseCate",
  SET_LIST_COURSE_CATE_DETAIL = "SET_LIST_COURSE_CATE_DETAIL",
}

enum CourseCateGetters {
  GET_BY_ID = "getCourseCateByCourseCateId",
  GET_ALL_CACHE_INFO = "getCourseCateCache",
  GET_COURSE_CATE_DETAIL_BY_ID = "getCourseCateDetailById",
}

export { CourseCateActions, CourseCateMutations, CourseCateGetters };
