export enum TeacherActions {
  // action types
  SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL = "searchByNameOrIdOrPhoneOrEmail",
  GET_LIST_TEACHER = "getListTeacher",
  CREATE = "createTeacher",
  UPDATE = "updateTeacher",
  DELETE = "delTeacher",
}

export enum TeacherMutations {
  // mutation types
  SET_LIST_TEACHER = "setListTeacher",
  SET_TOTAL_COUNT_TEACHER = "setTotalCountTeacher",
}

export enum TeacherGetters {
  GET_BY_ID = "getTeacherByTeacherId",
}
