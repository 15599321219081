enum LessonActions {
  // action types
  GET_ALL_LESSON_BY_COURSE_ID = "getAllLessonByCourseId",
  CREATE = "createLesson",
  UPDATE = "updateLesson",
  DELETE = "delLesson",
}

enum LessonMutations {
  // mutation types
  SET_LIST_LESSON = "setListLesson",
  SET_COURSE_ID = "setCourseId",
}

enum LessonGetters {
  GET_BY_ID = "getLessonByLessonId",
}

export { LessonActions, LessonMutations, LessonGetters };
