import Captain, { RequestMethod } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ReportActions, ReportMutations } from "./enums";

export interface ReportState {
  commonReport: Partial<Record<string, unknown>>;
  advanceReport: Partial<Record<string, unknown>>;
  errors: Array<string>;
}

@Module
export default class ReportModule extends VuexModule implements ReportState {
  uniqueKey = "report";
  commonReport: Record<string, unknown> = {};
  advanceReport: Record<string, unknown> = {};
  errors = [];

  @Mutation
  [ReportMutations.SET_COMMON_REPORT](report) {
    this.commonReport = report;
  }

  @Mutation
  [ReportMutations.SET_ADVANCE_REPORT](report) {
    this.advanceReport = report;
  }

  @Action({ rawError: true })
  async [ReportActions.GET_COMMON_REPORT]() {
    const year = new Date().getFullYear();

    const result = await Captain.push({
      url: `report/common`,
      method: RequestMethod.POST,
      body: {
        startDate: `2021-01-01`,
        endDate: `${year}-12-31`,
      },
    });
    return result.data;
  }

  @Action({ rawError: true })
  async [ReportActions.GET_REVENUE_REPORT](params) {
    const result = await Captain.push({
      url: `report/revenue`,
      method: RequestMethod.POST,
      body: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    });
    return result.data;
  }
}
