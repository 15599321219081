import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  StaticRoomActions,
  StaticRoomCustomerStatus,
  StaticRoomGetters,
  StaticRoomMutations,
} from "./enums";

export interface StaticRoom {
  id: number;
  name: string;
  image: string;
  description: string;
  long_desp: string;
  media: string;
  price: number;
  show_type: number;
  status: number;
  ordering: number;
}

export interface StaticRoomState {
  list: Partial<StaticRoom>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class StaticRoomModule
  extends VuexModule
  implements StaticRoomState
{
  uniqueKey = "static-room";
  list: StaticRoom[] = [];
  errors = [];

  get [StaticRoomGetters.GET_BY_ID]() {
    return (staticRoomId: number) => {
      const detail = this.list.filter((m) => m.id === staticRoomId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [StaticRoomMutations.SET_LIST_STATIC_ROOM](list: StaticRoom[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [StaticRoomActions.GET_ALL_STATIC_ROOM]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey + "/admin",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(
        StaticRoomMutations.SET_LIST_STATIC_ROOM,
        result?.data
      );
    }
  }

  @Action({ rawError: true })
  async [StaticRoomActions.GET_DETAIL_STATIC_ROOM](id) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      id: id,
      url: this.uniqueKey,
    });
    if (result?.isSuccess) {
      return result?.data;
    }
    console.error("Đã xảy ra lỗi khi get detail static room");
  }

  @Action({ rawError: true })
  async [StaticRoomActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    console.log(result);
    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StaticRoomActions.GET_ALL_STATIC_ROOM);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StaticRoomActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StaticRoomActions.GET_ALL_STATIC_ROOM);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StaticRoomActions.DELETE](staticRoomId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: staticRoomId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StaticRoomActions.GET_ALL_STATIC_ROOM);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StaticRoomActions.UPDATE_CUSTOMER_STATUS](par) {
    const result = await Captain.push({
      url: this.uniqueKey + "/status",
      method: RequestMethod.POST,
      body: {
        peopleId: par["peopleId"],
        staticRoomId: par["staticRoomId"],
        status: par["status"],
      },
    });
    return result;
  }

  @Action({ rawError: true })
  async [StaticRoomActions.GET_LIST_CUSTOMER](par) {
    const result = await Captain.push({
      url: this.uniqueKey + `/${par.staticRoomId}/people`,
      method: RequestMethod.POST,
      params: {
        limit: par.limit,
        page: par.page,
      },
    });
    return result;
  }
}
