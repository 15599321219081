export enum StudentActions {
  // action types
  GET_LIST_STUDENT = "getListStudent",
  CREATE = "createStudent",
  UPDATE = "updateStudent",
  DELETE = "delStudent",
  SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL = "searchStudentByNameOrIdOrPhoneOrEmail",
}

export enum StudentMutations {
  // mutation types
  SET_LIST_STUDENT = "setListStudent",
  SET_TOTAL_COUNT_STUDENT = "setTotalCountStudent",
}

export enum StudentGetters {
  GET_BY_ID = "getStudentByStudentId",
}
