export enum StaffActions {
  // action types
  SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL = "searchByNameOrIdOrPhoneOrEmail",
  GET_LIST_STAFF = "getListStaff",
  CREATE = "createStaff",
  UPDATE = "updateStaff",
  DELETE = "delStaff",
}

export enum StaffMutations {
  // mutation types
  SET_LIST_STAFF = "setListStaff",
  SET_TOTAL_COUNT_EMPLOYEE = "setTotalCountStaff",
}

export enum StaffGetters {
  GET_BY_ID = "getStaffByStaffId",
}
