enum ReportActions {
  // action types
  GET_COMMON_REPORT = "getCommonReport",
  GET_REVENUE_REPORT = "getAdvanceReport",
}

enum ReportMutations {
  // mutation types
  SET_COMMON_REPORT = "setCommonReport",
  SET_ADVANCE_REPORT = "setAdvanceReport",
}

export { ReportActions, ReportMutations };
