enum ClassActions {
  // action types
  GET_ALL_CLASS = "getAllClass",
  GET_DETAIL = "getDetailClass",
  CREATE = "createClass",
  UPDATE = "updateClass",
  DELETE = "delClass",
  GET_LIST_STUDENT = "getListStudentInClass",
  REMOVE_STUDENT_FROM_CLASS = "removeStudentFromClass",
  BAND_STUDENT_FROM_CLASS = "bandStudentFromClass",
  ADD_STUDENT_TO_CLASS = "addStudentToClass",
  GET_REPORT_ATTENDANCE = "getReportAttendance",
  GET_JSON_DATA_REPORT_ATTENDANCE = "getJsonDataReportAttendance",
}

enum ClassMutations {
  // mutation types
  SET_LIST_CLASS = "setListClass",
}

enum ClassGetters {
  GET_BY_ID = "getClassByClassId",
}

export { ClassActions, ClassMutations, ClassGetters };
