import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { People } from "../PeopleModule";
import { SearchPeopleConfig } from "../student/StudentModule";
import { StaffActions, StaffGetters, StaffMutations } from "./enums";

export type Employee = People;
export interface EmployeeState {
  totalCount: number;
  list: Partial<Employee>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class EmployeeModule
  extends VuexModule
  implements EmployeeState
{
  uniqueKey = "employee";
  list: Employee[] = [];
  totalCount = 0;
  errors = [];
  modalUpsertId = "modal-upsert-employee";

  get [StaffGetters.GET_BY_ID]() {
    return (employeeId: number) => {
      const detail = this.list.filter((m) => m.id === employeeId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [StaffMutations.SET_LIST_STAFF](list: Employee[]) {
    this.list = list;
  }

  @Mutation
  [StaffMutations.SET_TOTAL_COUNT_EMPLOYEE](totalCount: number) {
    this.totalCount = totalCount;
  }

  @Action({ rawError: true })
  async [StaffActions.GET_LIST_STAFF]() {
    // Tìm theo role employee, saler, marketer
    const query = {
      limit: 200,
      page: 1,
      sort: [
        {
          column: "id",
          type: "DESC",
        },
      ],
    };

    const result = await Captain.push({
      method: RequestMethod.POST,
      body: query,
      url: "people/staff",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(StaffMutations.SET_LIST_STAFF, result?.data);

      console.log(result);
      this.context.commit(
        StaffMutations.SET_TOTAL_COUNT_EMPLOYEE,
        result?.data.length
      );
    }
  }

  @Action({ rawError: true })
  async [StaffActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: "people",
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StaffActions.GET_LIST_STAFF);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StaffActions.DELETE](employeeId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: "people",
      id: employeeId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StaffActions.GET_LIST_STAFF);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StaffActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL](
    searchConfig: SearchPeopleConfig
  ) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `people/findPeopleByTypesAndKeyword?peopleTypes[]=employee&peopleTypes[]=saler&peopleTypes[]=marketer&keyword=${searchConfig.keyword}`,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(StaffMutations.SET_LIST_STAFF, result?.data);
      this.context.commit(
        StaffMutations.SET_TOTAL_COUNT_EMPLOYEE,
        result?.data.length
      );
    }
  }
}
