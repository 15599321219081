import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { People } from "../PeopleModule";
import { SearchPeopleConfig } from "../student/StudentModule";
import { GuestActions, GuestGetters, GuestMutations } from "./enums";

export type Guest = People;
export interface GuestState {
  totalCount: number;
  list: Partial<Guest>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class GuestModule extends VuexModule implements GuestState {
  uniqueKey = "guest";
  list: Guest[] = [];
  totalCount = 0;
  errors = [];
  modalUpsertId = "modal-upsert-guest";

  get [GuestGetters.GET_BY_ID]() {
    return (guestId: number) => {
      const detail = this.list.filter((m) => m.id === guestId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [GuestMutations.SET_LIST_GUEST](list: Guest[]) {
    this.list = list;
  }

  @Mutation
  [GuestMutations.SET_TOTAL_COUNT_GUEST](totalCount: number) {
    this.totalCount = totalCount;
  }

  @Action({ rawError: true })
  async [GuestActions.GET_LIST_GUEST]() {
    const query = {
      limit: 50,
      page: 1,
      filter: [
        {
          column: "type",
          type: "exact",
          value: this.uniqueKey,
        },
      ],
      sort: [
        {
          column: "id",
          type: "DESC",
        },
      ],
    };

    const result = await Captain.push({
      method: RequestMethod.POST,
      body: query,
      url: "people/list",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(GuestMutations.SET_LIST_GUEST, result?.data);

      console.log(result);
      this.context.commit(
        GuestMutations.SET_TOTAL_COUNT_GUEST,
        result?.data.length
      );
    }
  }

  @Action({ rawError: true })
  async [GuestActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: "people",
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(GuestActions.GET_LIST_GUEST);
    }
    return result;
  }

  @Action({ rawError: true })
  async [GuestActions.DELETE](guestId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: "people",
      id: guestId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(GuestActions.GET_LIST_GUEST);
    }
    return result;
  }

  @Action({ rawError: true })
  async [GuestActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL](
    searchConfig: SearchPeopleConfig
  ) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `people/findPeopleByTypesAndKeyword?peopleTypes[]=guest&keyword=${searchConfig.keyword}`,
    });
    if (
      result?.isSuccess &&
      result?.data &&
      searchConfig.isCommitToModuleList
    ) {
      this.context.commit(GuestMutations.SET_LIST_GUEST, result?.data);
    }
    return result;
  }
}
