import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ScheduleActions, ScheduleGetters, ScheduleMutations } from "./enums";

export interface Schedule {
  branch_id: string;
  class_id: number;
  course_id: string;
  created_at: string;
  date: string;
  end_time: string;
  exist_meeting: number;
  id: number;
  lesson_id: number;
  lesson_name: string;
  meeting: string;
  meetingLive: Record<string, string>;
  hostEmail: string;
  hostId: string;
  joinUrl: string;
  meetingId: string;
  pwd: string;
  startTime: string;
  startUrl: string;
  start_time: string;
  status: number;
  teacher_id: number;
  type_code: number;
  updated_at: string;
}

export interface ScheduleState {
  list: Partial<Schedule>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class ScheduleModule
  extends VuexModule
  implements ScheduleState
{
  uniqueKey = "schedule";
  list: Schedule[] = [];
  errors = [];
  modalUpsertId = "modal-upsert-schedule";

  get [ScheduleGetters.GET_BY_ID]() {
    return (scheduleId: number) => {
      const detail = this.list.filter((m) => m.id === scheduleId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [ScheduleMutations.SET_LIST_SCHEDULE](list: Schedule[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID](classId) {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: `schedule/class/${classId}`,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(ScheduleMutations.SET_LIST_SCHEDULE, result?.data);
    }
  }

  @Action({ rawError: true })
  async [ScheduleActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID);
    }
    return result;
  }

  @Action({ rawError: true })
  async [ScheduleActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(
        ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID,
        par.class_id
      );
    }
    return result;
  }

  @Action({ rawError: true })
  async [ScheduleActions.DELETE](params) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: params.scheduleId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(
        ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID,
        params.classId
      );
    }
    return result;
  }

  @Action({ rawError: true })
  async [ScheduleActions.GENERATE_SCHEDULE](classId) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      url: `schedule/generate/${classId}`,
    });

    if (result?.isSuccess) {
      this.context.dispatch(
        ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID,
        classId
      );
    }
    return result;
  }

  @Action({ rawError: true })
  async [ScheduleActions.GENERATE_ZOOM_MEETING](classId) {
    const result = await Captain.push({
      method: RequestMethod.GET,
      url: `schedule/meeting/${classId}`,
    });

    if (result?.isSuccess) {
      this.context.dispatch(
        ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID,
        classId
      );
    }
    return result;
  }
}
