enum BranchActions {
  // action types
  GET_ALL_BRANCH = "getAllBranch",
  CREATE = "createBranch",
  UPDATE = "updateBranch",
  DELETE = "delBranch",
}

enum BranchMutations {
  // mutation types
  SET_LIST_BRANCH = "setListBranch",
}

enum BranchGetters {
  GET_BY_ID = "getBranchByBranchId",
}

export { BranchActions, BranchMutations, BranchGetters };
