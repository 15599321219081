enum EventEduActions {
  // action types
  GET_ALL_EVENT_EDU = "getAllEventEdu",
  CREATE = "createEventEdu",
  UPDATE = "updateEventEdu",
  DELETE = "delEventEdu",
  GET_DETAIL = "getDetailEventEdu",
  CREATE_ZOOM_MEETING_AUTO = "createZoomMeetingEventEduAuto",
  CREATE_ZOOM_MEETING_MANUAL = "createZoomMeetingEventEduManual",
  DEL_ZOOM_MEETING = "delZoomMeetingEventEdu",
}

enum EventEduMutations {
  // mutation types
  SET_LIST_EVENT_EDU = "setListEventEdu",
}

enum EventEduGetters {
  GET_BY_ID = "getEventEduByEventEduId",
}

export { EventEduActions, EventEduMutations, EventEduGetters };
