import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { NewsCateActions, NewsCateGetters, NewsCateMutations } from "./enums";

export interface NewsCate {
  cate_id: number;
  name: string;
  desp: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface NewsCateDetail {
  detail_cate_id: number;
  id: number; // this is news id
  cate_id: number;
  point: number;
}

export interface NewsCateState {
  list: Partial<NewsCate>[];
  listDetail: Partial<NewsCateDetail>[];
  listDetailByDetailId: Record<number, NewsCateDetail>;
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class NewsCateModule
  extends VuexModule
  implements NewsCateState
{
  uniqueKey = "news-cate";
  list: NewsCate[] = [];
  listDetail: NewsCateDetail[] = [];

  // List này có thể chứa thông tin chi tiết của tất cả news cate mà ko bị trùng nhau
  listDetailByDetailId: Record<number, NewsCateDetail> = {};

  errors = [];
  modalUpsertId = "modal-upsert-news-cate";
  modalUpdatePointId = "modal-update-point-in-news-cate";

  get [NewsCateGetters.GET_BY_ID]() {
    return (newsCateId: number) => {
      const detail = this.list.filter((m) => m.cate_id === newsCateId);
      return detail.length ? detail[0] : {};
    };
  }

  get [NewsCateGetters.GET_ALL_CACHE_INFO]() {
    return () => {
      return this.list;
    };
  }

  get [NewsCateGetters.GET_NEWS_CATE_DETAIL_BY_ID]() {
    return (newsCateDetailId: number) => {
      const detail = this.listDetailByDetailId[newsCateDetailId];
      return detail || {};
    };
  }

  @Mutation
  [NewsCateMutations.SET_LIST_NEWS_CATE](list: NewsCate[]) {
    this.list = list;
  }

  @Mutation
  [NewsCateMutations.SET_LIST_NEWS_CATE_DETAIL](list: NewsCateDetail[]) {
    this.listDetail = list;
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.detail_cate_id) {
        this.listDetailByDetailId[element.detail_cate_id] = element;
      }
    }
  }

  @Action({ rawError: true })
  async [NewsCateActions.GET_ALL_NEWS_CATE]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(NewsCateMutations.SET_LIST_NEWS_CATE, result?.data);
    }
  }

  @Action({ rawError: true })
  async [NewsCateActions.GET_ALL_NEWS_BY_CATE_ID](par) {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: `news/category/${par.id}`,
      data: {
        page: par?.page || 1,
      },
    });

    if (result?.isSuccess) {
      this.context.commit(
        NewsCateMutations.SET_LIST_NEWS_CATE_DETAIL,
        result?.data
      );

      return result.data;
    }
  }

  @Action({ rawError: true })
  async [NewsCateActions.GET_DETAIL](cateId) {
    const result = await Captain.handle({
      action: ModuleAction.DETAIL,
      url: this.uniqueKey,
      id: cateId,
    });
    if (result?.isSuccess) {
      return result.data;
    }
  }

  @Action({ rawError: true })
  async [NewsCateActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsCateActions.GET_ALL_NEWS_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NewsCateActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.cate_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsCateActions.GET_ALL_NEWS_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NewsCateActions.UPDATE_DETAIL_POINT](par) {
    const result = await Captain.push({
      url: `news/point/${par.detail_cate_id}?point=${par.point}`,
      method: RequestMethod.GET,
    });
    return result;
  }

  @Action({ rawError: true })
  async [NewsCateActions.DELETE](newsCateId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: newsCateId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(NewsCateActions.GET_ALL_NEWS_CATE);
    }
    return result;
  }

  @Action({ rawError: true })
  async [NewsCateActions.REMOVE_NEWS_FROM_CATE](detailCateId) {
    const result = await Captain.push({
      url: `news/remove-cate/${detailCateId}`,
      method: RequestMethod.GET,
    });
    return result;
  }
}
