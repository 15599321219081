enum StaticRoomActions {
  // action types
  GET_ALL_STATIC_ROOM = "getAllStaticRoom",
  GET_DETAIL_STATIC_ROOM = "getDetailStaticRoom",
  CREATE = "createStaticRoom",
  UPDATE = "updateStaticRoom",
  DELETE = "delStaticRoom",
  UPDATE_CUSTOMER_STATUS = "updateCustomerStatus",
  GET_LIST_CUSTOMER = "getListCustomer",
}

enum StaticRoomMutations {
  // mutation types
  SET_LIST_STATIC_ROOM = "setListStaticRoom",
}

enum StaticRoomGetters {
  GET_BY_ID = "getStaticRoomByStaticRoomId",
}

enum StaticRoomCustomerStatus {
  ACTIVE = 0,
  INACTIVE = 1, // Bị thu hồi permission
  LOCK = 2, // Vi phạm chính sách
}

export {
  StaticRoomActions,
  StaticRoomMutations,
  StaticRoomGetters,
  StaticRoomCustomerStatus,
};
