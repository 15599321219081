import { generateRandomString } from "@/core/helpers/documentation";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AudioActions, AudioGetters, AudioMutations } from "./enums";

export interface Audio {
  audioId: string;
  src: number;
  name: string;
  order: number;
  defaultTime: number;
  min: number;
  max: number;
}

export interface AudioState {
  list: Partial<Audio>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class AudioModule extends VuexModule implements AudioState {
  uniqueKey = "audio";
  list: Audio[] = [];
  errors = [];
  audioIdTargetUpsert = "";

  get [AudioGetters.GET_BY_ID]() {
    return (id: string) => {
      const detail = this.list.filter((m) => m.audioId === id);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [AudioMutations.SET_AUDIO_ID_TARGET_UPSERT](audioId) {
    this.audioIdTargetUpsert = audioId;
  }

  @Mutation
  [AudioMutations.SET_LIST_AUDIO](list: Audio[]) {
    list.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = list;
  }

  @Mutation
  async [AudioActions.CREATE](par) {
    const notRef = par;
    const newId = generateRandomString(8);

    // TODO
    // Try not ref
    notRef.audioId = newId;
    const audioList = this.list;
    audioList.push(notRef);

    audioList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });
    this.list = audioList;
  }

  @Mutation
  async [AudioActions.UPDATE](par: Audio) {
    const audioId = par.audioId;
    const audioList = this.list;
    for (let index = 0; index < audioList.length; index++) {
      const element = audioList[index];
      if (element.audioId === audioId) {
        audioList[index] = par;
        break;
      }
    }

    audioList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = audioList;
  }

  @Mutation
  async [AudioActions.DELETE](audioId) {
    const audioList = this.list;
    for (let index = 0; index < audioList.length; index++) {
      const element = audioList[index];
      if (element.audioId === audioId) {
        audioList.splice(index, 1);
        break;
      }
    }

    audioList.sort((a, b) => {
      const aOrder = parseInt(a.order as any);
      const bOrder = parseInt(b.order as any);
      return aOrder > bOrder ? 1 : -1;
    });

    this.list = audioList;
  }
}
