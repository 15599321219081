import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { LessonActions, LessonGetters, LessonMutations } from "./enums";

export enum LessonType {
  ONLINE = "online",
  OFFLINE = "offline",
  DOCUMENT = "document",
  VIDEO = "video",
}

export interface Lesson {
  lesson_id: number;
  course_id: number;
  number_shifts: number;
  name: string;
  video: string;
  content: string;
  type: LessonType;
}

export interface LessonState {
  list: Partial<Lesson>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class LessonModule extends VuexModule implements LessonState {
  uniqueKey = "lesson";
  courseId: boolean | number = false;
  list: Lesson[] = [];
  errors = [];

  get [LessonGetters.GET_BY_ID]() {
    return (lessonId: number) => {
      const detail = this.list.filter((m) => m.lesson_id === lessonId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [LessonMutations.SET_LIST_LESSON](list: Lesson[]) {
    this.list = list;
  }

  @Mutation
  [LessonMutations.SET_COURSE_ID](courseId: number) {
    this.courseId = courseId;
  }

  @Action({ rawError: true })
  async [LessonActions.GET_ALL_LESSON_BY_COURSE_ID]() {
    if (this.courseId) {
      const result = await Captain.handle({
        action: ModuleAction.ALL,
        url: "lesson/course/" + this.courseId,
      });

      if (result?.isSuccess && Array.isArray(result?.data)) {
        this.context.commit(LessonMutations.SET_LIST_LESSON, result?.data);
      }
    } else {
      this.list = [];
    }
  }

  @Action({ rawError: true })
  async [LessonActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(LessonActions.GET_ALL_LESSON_BY_COURSE_ID);
    }
    return result;
  }

  @Action({ rawError: true })
  async [LessonActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.lesson_id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(LessonActions.GET_ALL_LESSON_BY_COURSE_ID);
    }
    return result;
  }

  @Action({ rawError: true })
  async [LessonActions.DELETE](lessonId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: lessonId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(LessonActions.GET_ALL_LESSON_BY_COURSE_ID);
    }
    return result;
  }
}
