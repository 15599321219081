import Captain, {
  ModuleAction,
  RequestMethod,
} from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { People } from "../PeopleModule";
import { StudentActions, StudentGetters, StudentMutations } from "./enums";

export type Student = People;
export interface StudentState {
  totalCount: number;
  list: Partial<Student>[];
  uniqueKey: string;
  errors: Array<string>;
}

export interface SearchPeopleConfig {
  keyword: string;
  isCommitToModuleList?: boolean;
}

@Module
export default class StudentModule extends VuexModule implements StudentState {
  uniqueKey = "student";
  list: Student[] = [];
  totalCount = 0;
  errors = [];
  modalUpsertId = "modal-upsert-student";

  get [StudentGetters.GET_BY_ID]() {
    return (studentId: number) => {
      const detail = this.list.filter((m) => m.id === studentId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [StudentMutations.SET_LIST_STUDENT](list: Student[]) {
    this.list = list;
    this.totalCount = list.length;
  }

  @Action({ rawError: true })
  async [StudentActions.GET_LIST_STUDENT]() {
    const query = {
      limit: 50,
      page: 1,
      filter: [
        {
          column: "type",
          type: "exact",
          value: this.uniqueKey,
        },
      ],
      sort: [
        {
          column: "id",
          type: "DESC",
        },
      ],
    };

    const result = await Captain.push({
      method: RequestMethod.POST,
      body: query,
      url: "people/list",
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(StudentMutations.SET_LIST_STUDENT, result?.data);
      console.log(result);
    }
  }

  @Action({ rawError: true })
  async [StudentActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: "people",
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StudentActions.GET_LIST_STUDENT);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StudentActions.DELETE](studentId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: "people",
      id: studentId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(StudentActions.GET_LIST_STUDENT);
    }
    return result;
  }

  @Action({ rawError: true })
  async [StudentActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL](
    searchConfig: SearchPeopleConfig
  ) {
    const result = await Captain.push({
      method: RequestMethod.POST,
      // get all role (people type) ở đây
      url: `people/findPeopleByTypesAndKeyword?keyword=${searchConfig.keyword}`,
    });
    if (
      result?.isSuccess &&
      result?.data &&
      searchConfig.isCommitToModuleList
    ) {
      this.context.commit(StudentMutations.SET_LIST_STUDENT, result?.data);
    }
    return result;
  }
}
