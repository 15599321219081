import Captain, { ModuleAction } from "@/store/captain/CaptainModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { SiriActions, SiriGetters, SiriMutations } from "./enums";

export interface Siri {
  id: number;
  text: string;
  targetId: number;
  targetType: string;
  targetExtra: string;
  index: string;
  point_index: number;
  level: number;
  type: string;
}

export interface SiriState {
  list: Partial<Siri>[];
  uniqueKey: string;
  errors: Array<string>;
}

@Module
export default class SiriModule extends VuexModule implements SiriState {
  uniqueKey = "siri";
  list: Siri[] = [];
  errors = [];
  modalUpsertId = "modal-upsert-siri";

  get [SiriGetters.GET_BY_ID]() {
    return (siriId: number) => {
      const detail = this.list.filter((m) => m.id === siriId);
      return detail.length ? detail[0] : {};
    };
  }

  @Mutation
  [SiriMutations.SET_LIST_SIRI](list: Siri[]) {
    this.list = list;
  }

  @Action({ rawError: true })
  async [SiriActions.GET_ALL_SIRI]() {
    const result = await Captain.handle({
      action: ModuleAction.ALL,
      url: this.uniqueKey,
    });

    if (result?.isSuccess && Array.isArray(result?.data)) {
      this.context.commit(SiriMutations.SET_LIST_SIRI, result?.data);
    }
  }

  @Action({ rawError: true })
  async [SiriActions.CREATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.CREATE,
      url: this.uniqueKey,
      data: par,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(SiriActions.GET_ALL_SIRI);
    }
    return result;
  }

  @Action({ rawError: true })
  async [SiriActions.UPDATE](par) {
    const result = await Captain.handle({
      action: ModuleAction.UPDATE,
      url: this.uniqueKey,
      data: par,
      id: par.id,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(SiriActions.GET_ALL_SIRI);
    }
    return result;
  }

  @Action({ rawError: true })
  async [SiriActions.DELETE](siriId) {
    const result = await Captain.handle({
      action: ModuleAction.DELETE,
      url: this.uniqueKey,
      id: siriId,
    });

    if (result?.isSuccess && result?.data) {
      this.context.dispatch(SiriActions.GET_ALL_SIRI);
    }
    return result;
  }
}
