/**
 * @description Captain's logger
 */
class Logger {
  public static log(msg) {
    console.log(msg);
  }
  public static exception(e) {
    console.log(e);
  }
}

export default Logger;
