enum CourseActions {
  // action types
  GET_ALL_COURSE = "getAllCourse",
  GET_DETAIL = "getDetailCourse",
  CREATE = "createCourse",
  UPDATE = "updateCourse",
  DELETE = "delCourse",
  SET_STRUCT = "setStructCourse",
  GET_STRUCT = "getStructCourse",
  ADD_COURSE_TO_CATE = "addCourseToCate",
}

enum CourseMutations {
  // mutation types
  SET_LIST_COURSE = "setListCourse",
  SET_TREE_DATA_LESSON = "setTreeDataLesson",
}

enum CourseGetters {
  GET_BY_ID = "getCourseByCourseId",
}

export { CourseActions, CourseMutations, CourseGetters };
