import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Captain from "../captain/CaptainModule";
export interface User {
  people_id: number;
  firstname: string;
  lastname: string;
  avatar: string;
  userId: number;
  role?: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

export interface LoginResponse {
  status: string;
  access_token?: string;
  user_id?: string;
  fullname?: string;
  avatar?: string;
  message?: string;
}

export enum ResponseStatus {
  SUCCESS = "success",
  ERROR = "error",
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  accessToken = "";
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    if (user["access_token"]) {
      Captain.setAccessTokenToLocalStorage(user["access_token"]);
      this.accessToken = user["access_token"]; // for api /verify
    }
    Captain.loadAccessTokenFromLocalStorage();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    router.push({ name: "sign-in" });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      const url = `${process.env.VUE_APP_OAUTH_SERVER_URL}/api/login/`;
      credentials["app_id"] = process.env.VUE_APP_OAUTH_APP_ID;
      credentials["secret"] = process.env.VUE_APP_OAUTH_APP_SECRET;

      console.log(credentials);

      ApiService.post(url, credentials)
        .then(({ data }) => {
          const res: LoginResponse = data;
          if (res.status === ResponseStatus.SUCCESS) {
            this.context.commit(Mutations.SET_AUTH, data);
            console.log(data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [res.message]);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  async [Actions.VERIFY_AUTH]() {
    await Captain.get("account/verify", {
      headers: {
        "vm-token": this.accessToken,
      },
      params: {},
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        localStorage.setItem("vm-people-role", data?.role);
      })
      .catch(({ response }) => {
        console.log("Đã xảy ra lỗi trong quá trình xác thực token");
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response["status"] === 401) {
          this.context.commit(Mutations.PURGE_AUTH);
        }
      });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
